import React, { useState } from 'react';

const Logout = () => {
    const [hoveredButton, setHoveredButton] = useState('');

    const handleLogout = () => {
        alert('You have been logged out!');
        window.location.href = '/login'; // Redirect to login page
    };

    const handleCancel = () => {
        window.history.back(); // Navigate back to the previous page
    };

    const buttonStyles = (type) => ({
        backgroundColor: type === hoveredButton 
            ? (type === 'logout' ? '#ff3b3f' : '#33c4ff') 
            : type === 'logout' 
                ? '#ff575d' 
                : '#47d1ff',
        color: 'white',
        padding: '12px 24px',
        border: 'none',
        borderRadius: '50px',
        fontSize: '1.1em',
        cursor: 'pointer',
        transition: 'transform 0.3s ease, background-color 0.3s ease',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        width: '45%',
        textAlign: 'center',
        transform: hoveredButton === type ? 'scale(1.1)' : 'scale(1)',
    });

    return (
        <div style={styles.wrapper}>
            <div style={styles.container}>
                <div style={styles.circle}></div>
                <img
                    src="https://via.placeholder.com/100/ff575d/ffffff?text=🔒" 
                    alt="Logout Icon"
                    style={styles.icon}
                />
                <h2 style={styles.heading}>Goodbye for now!</h2>
                <p style={styles.message}>
                    Are you sure you want to leave? We'll miss you!
                </p>
                <div style={styles.buttonGroup}>
                    <button
                        style={buttonStyles('logout')}
                        onMouseEnter={() => setHoveredButton('logout')}
                        onMouseLeave={() => setHoveredButton('')}
                        onClick={handleLogout}
                    >
                        Yes, Log Out
                    </button>
                    <button
                        style={buttonStyles('cancel')}
                        onMouseEnter={() => setHoveredButton('cancel')}
                        onMouseLeave={() => setHoveredButton('')}
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    wrapper: {
        background: 'linear-gradient(to bottom right, #ff9a9e, #fad0c4, #fbc2eb)',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        animation: 'gradient 6s infinite ease-in-out alternate',
    },
    container: {
        width: '90%',
        maxWidth: '450px',
        backgroundColor: 'white',
        padding: '40px',
        borderRadius: '15px',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.3)',
        textAlign: 'center',
        position: 'relative',
    },
    circle: {
        position: 'absolute',
        width: '150px',
        height: '150px',
        backgroundColor: '#ff9a9e',
        borderRadius: '50%',
        top: '-50px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: '-1',
    },
    icon: {
        width: '80px',
        height: '80px',
        marginBottom: '20px',
        animation: 'bounce 1.5s infinite',
    },
    heading: {
        fontSize: '1.8em',
        color: '#343a40',
        marginBottom: '15px',
        fontWeight: 'bold',
    },
    message: {
        fontSize: '1em',
        color: '#6c757d',
        marginBottom: '30px',
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    '@keyframes bounce': {
        '0%, 20%, 50%, 80%, 100%': {
            transform: 'translateY(0)',
        },
        '40%': {
            transform: 'translateY(-15px)',
        },
        '60%': {
            transform: 'translateY(-7px)',
        },
    },
    '@keyframes gradient': {
        '0%': { backgroundPosition: '0% 50%' },
        '50%': { backgroundPosition: '100% 50%' },
        '100%': { backgroundPosition: '0% 50%' },
    },
};

export default Logout;
