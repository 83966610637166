import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

// Sidebar Component
const Sidebar = () => {
  const sidebarStyle = {
    width: '250px',
    backgroundColor: '#343a40',
    color: 'white',
    paddingTop: '20px',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingRight: '10px',
  };

  const sidebarItemStyle = {
    margin: '15px 0',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '1.1em',
  };

  return (
    <div style={sidebarStyle}>
      <div style={sidebarItemStyle}>
        <Link to="/courses/1" style={{ color: 'white', textDecoration: 'none' }}>Cybersecurity Basics</Link>
      </div>
      <div style={sidebarItemStyle}>
        <Link to="/courses/2" style={{ color: 'white', textDecoration: 'none' }}>Networking Fundamentals</Link>
      </div>
      <div style={sidebarItemStyle}>
        <Link to="/courses/3" style={{ color: 'white', textDecoration: 'none' }}>Advanced Security</Link>
      </div>
      <div style={sidebarItemStyle}>
        <Link to="/courses" style={{ color: 'white', textDecoration: 'none' }}>All Training Lectures</Link>
      </div>
    </div>
  );
};

const CourseVideos = () => {
  const { id } = useParams(); // Get the course ID from the URL
  const [videos, setVideos] = useState([]);

  // Cybersecurity and Networking Training Lectures
  const trainingLectures = [
    { name: 'Cybersecurity Basics - Introduction', file: 'https://www.w3schools.com/html/mov_bbb.mp4' },
    { name: 'Understanding Firewalls', file: 'https://www.w3schools.com/html/movie.mp4' },
    { name: 'Networking Fundamentals - Introduction', file: 'https://www.w3schools.com/html/mov_bbb.mp4' },
    { name: 'Secure Networking Protocols', file: 'https://www.w3schools.com/html/movie.mp4' },
    { name: 'Advanced Cyber Threats', file: 'https://www.w3schools.com/html/mov_bbb.mp4' },
    { name: 'Incident Response Training', file: 'https://www.w3schools.com/html/movie.mp4' },
  ];

  useEffect(() => {
    // Filter based on the course ID, otherwise show all training lectures
    if (id) {
      const filteredLectures = trainingLectures.slice(0, 2 + parseInt(id, 10)); // Example filtering logic
      setVideos(filteredLectures);
    } else {
      setVideos(trainingLectures); // Show all training lectures by default
    }
  }, [id]);

  const mainContainerStyle = {
    marginLeft: '250px', // For sidebar space
    padding: '20px',
    display: 'flex',
    flexWrap: 'wrap', // Allow items to wrap
    gap: '20px', // Add space between items
    alignItems: 'flex-start',
  };

  const videoCardStyle = {
    display: 'flex',
    flexDirection: 'column', // Ensure videos and titles stack vertically
    alignItems: 'center',
    width: '300px', // Set a fixed width for video cards
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: '10px',
    backgroundColor: 'white',
    borderRadius: '8px',
  };

  return (
    <div style={{ display: 'flex' }}>
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div style={mainContainerStyle}>
        <h2 style={{ width: '100%' }}>{id ? `Course ${id} - Training Lectures` : 'All Training Lectures on Cybersecurity and Networking'}</h2>
        {videos.length > 0 ? (
          videos.map((video, index) => (
            <div key={index} style={videoCardStyle}>
              <h3>{video.name}</h3>
              <video controls width="100%">
                <source src={video.file} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ))
        ) : (
          <p>No videos available.</p>
        )}
      </div>
    </div>
  );
};

export default CourseVideos;
