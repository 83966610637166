import React from 'react';
import { Container, Typography, Paper, Box, Grid, List, ListItem, ListItemText, Divider, Button, Avatar } from '@mui/material';
import { blue, grey, orange } from '@mui/material/colors';

const TermsAndConditions = () => {
  return (
    <Container maxWidth="lg" sx={{ padding: '40px 20px' }}>
      {/* Hero Section */}
      <Box
        sx={{
            background: `linear-gradient(135deg, #FFD59E 0%, #FFA45B 100%)`,
            padding: '60px 20px',
            borderRadius: 2,
            color: 'white',
            boxShadow: 2,
            marginBottom: 5,
            textAlign: 'center',
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: 'bold', letterSpacing: 1 }}>
          Terms & Conditions
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          Last updated on 20-11-2024
        </Typography>
      </Box>

      {/* Terms Content */}
      <Paper sx={{ padding: '30px', backgroundColor: '#ffffff', boxShadow: 3, borderRadius: 2 }}>
        <Typography variant="body1" paragraph>
          These Terms and Conditions, along with the privacy policy or other terms (“Terms”) constitute a binding agreement between codevocado ("Website Owner," "we," "us," or "our") and you ("you" or "your") and relate to your use of our website, goods, or services (collectively, "Services").
        </Typography>

        <Typography variant="body1" paragraph>
          By using our website and availing the Services, you agree that you have read and accepted these Terms. We reserve the right to modify these Terms at any time. It is your responsibility to periodically review these Terms for updates.
        </Typography>

        {/* Grid layout for list items */}
        <Grid container spacing={4} sx={{ marginTop: 4 }}>
          {/* Section 1 */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ padding: 3, backgroundColor: '#fafafa', boxShadow: 2, borderRadius: 2 }}>
              <Typography variant="h6" sx={{ color: blue[700], marginBottom: 2 }}>
                Use of Services
              </Typography>
              <List>
                <ListItem>
                  <ListItemText
                    primary="You agree to provide true, accurate, and complete information during registration."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="We do not guarantee the accuracy or completeness of the information provided."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Your use of the Services is at your own risk, and you must assess if the Services meet your requirements."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Unauthorized use of the Website or Services may result in legal action." />
                </ListItem>
              </List>
            </Paper>
          </Grid>

          {/* Section 2 */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ padding: 3, backgroundColor: '#fafafa', boxShadow: 2, borderRadius: 2 }}>
              <Typography variant="h6" sx={{ color: blue[700], marginBottom: 2 }}>
                Refunds & Payment
              </Typography>
              <List>
                <ListItem>
                  <ListItemText
                    primary="You agree to pay for the Services as specified."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="If we cannot provide the Service, you are entitled to a refund."
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: 5 }}>
          <Typography variant="h6" sx={{ color: blue[700], marginBottom: 2 }}>
            Governing Law
          </Typography>
          <Typography variant="body1" paragraph>
            These Terms are governed by the laws of India. Disputes arising from these Terms will be subject to the exclusive jurisdiction of the courts in Deoria, Uttar Pradesh.
          </Typography>
        </Box>

        {/* Footer Section */}
        <Box sx={{ marginTop: 5, textAlign: 'center' }}>
  <Divider />
  <Box
    sx={{
      padding: '20px 0',
      background: 'linear-gradient(135deg, #FFD59E 0%, #FFA45B 100%)',
      borderRadius: 2,
      color: 'white',
      boxShadow: 2,
    }}
  >
    <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
      &copy; 2024 Codevocado. All rights reserved.
    </Typography>
  </Box>
</Box>

      </Paper>
    </Container>
  );
};

export default TermsAndConditions;
