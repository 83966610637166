import React from 'react';

function WelcomeBanner() {
  return (
    <section className="welcome-banner" style={bannerStyle}>
      <h1 style={headerStyle}>Welcome, Codevocado LMS Dashboard!</h1>
      <p style={subheaderStyle}>Your journey to certification starts here!</p>
      <div className="progress-bar" style={progressBarContainerStyle}>
        <span style={progressTextStyle}>Overall Progress: 70%</span>
        <div className="progress" style={progressBarStyle}>
          <div className="progress-fill" style={{ ...progressFillStyle, width: '70%' }}></div>
        </div>
      </div>
    </section>
  );
}

// Inline styles for the component
const bannerStyle = {
  backgroundColor: '#ffffff',
  padding: '30px',
  borderRadius: '10px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  marginBottom: '20px',
};

const headerStyle = {
  fontSize: '2em',
  color: '#333',
  marginBottom: '10px',
  fontWeight: 'bold',
};

const subheaderStyle = {
  fontSize: '1.2em',
  color: '#666',
  marginBottom: '20px',
};

const progressBarContainerStyle = {
  width: '100%',
  backgroundColor: '#f3f3f3',
  padding: '10px',
  borderRadius: '5px',
  boxSizing: 'border-box',
};

const progressTextStyle = {
  fontSize: '1.1em',
  color: '#333',
  marginBottom: '10px',
  display: 'block',
};

const progressBarStyle = {
  width: '100%',
  backgroundColor: '#e0e0e0',
  borderRadius: '5px',
  height: '10px',
  overflow: 'hidden',
};

const progressFillStyle = {
  height: '100%',
  backgroundColor: '#4caf50',
  borderRadius: '5px',
};

export default WelcomeBanner;
