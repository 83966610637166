import React from 'react';
import './CancellationAndRefundPolicy.css';

const CancellationAndRefundPolicy = () => {
  return (
    <div className="policy-container">
      <section className="policy-section">
        <div className="content-container">
          <h1>Cancellation & Refund Policy</h1>
          <p><strong>Last updated on 20-11-2024 18:39:01</strong></p>
          
          <p>codevocado believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:</p>
          
          <h2>Cancellation Policy</h2>
          <ul>
            <li>Cancellations will be considered only if the request is made immediately after placing the order. However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them.</li>
            <li>codevocado does not accept cancellation requests for perishable items like flowers, eatables etc. However, refund/replacement can be made if the customer establishes that the quality of product delivered is not good.</li>
          </ul>

          <h2>Damaged or Defective Items</h2>
          <p>In case of receipt of damaged or defective items, please report the same to our Customer Service team. The request will, however, be entertained once the merchant has checked and determined the same at his own end. This should be reported within <strong>30 days</strong> of receipt of the products.</p>
          <p>In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within <strong>30 days</strong> of receiving the product. The Customer Service Team, after looking into your complaint, will take an appropriate decision.</p>

          <h2>Warranty-Related Complaints</h2>
          <p>In case of complaints regarding products that come with a warranty from manufacturers, please refer the issue to them.</p>
          
          <h2>Refund Process</h2>
          <p>In case of any refunds approved by codevocado, it’ll take <strong>16-30 days</strong> for the refund to be processed to the end customer.</p>

          <h2>Contact Us</h2>
          <p>If you have any further questions or concerns regarding our cancellation and refund policy, please don't hesitate to reach out to our Customer Service team at <a href="mailto:codevocado.in@example.com">support@example.com</a>.</p>
        </div>
      </section>

      {/* <footer className="footer">
        <p>&copy; 2024 Your Company. All rights reserved.</p>
      </footer> */}
    </div>
  );
};

export default CancellationAndRefundPolicy;
