const Questions = () => {
    const containerStyle = {
      padding: '40px 50px',
      backgroundColor: '#eef2f7',
      borderRadius: '12px',
      boxShadow: '0 8px 24px rgba(0, 0, 0, 0.1)',
      maxWidth: '900px',
      margin: 'auto',
      marginTop: '40px',
      textAlign: 'center',
    };
  
    const headingStyle = {
      fontSize: '2.8em',
      fontWeight: 'bold',
      color: '#2d3436',
      marginBottom: '30px',
      textTransform: 'uppercase',
      letterSpacing: '2px',
    };
  
    const questionContainerStyle = {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
      gap: '20px',
      marginTop: '20px',
    };
  
    const questionStyle = {
      padding: '25px',
      backgroundColor: '#ffffff',
      borderRadius: '10px',
      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.05)',
      transition: 'transform 0.3s, box-shadow 0.3s',
      cursor: 'pointer',
    };
  
    const questionTextStyle = {
      fontSize: '1.25em',
      fontWeight: '500',
      color: '#333',
      marginBottom: '15px',
      lineHeight: '1.5',
    };
  
    const buttonStyle = {
      display: 'inline-block',
      padding: '12px 30px',
      backgroundColor: '#0069d9',
      color: '#fff',
      fontWeight: '500',
      fontSize: '1em',
      borderRadius: '5px',
      textDecoration: 'none',
      transition: 'background-color 0.3s, transform 0.3s',
      border: 'none',
      boxSizing: 'border-box',
    };
  
    const buttonHoverStyle = {
      backgroundColor: '#0056b3',
      transform: 'translateY(-3px)',
    };
  
    const questions = [
      { id: 1, text: 'What is a firewall in cybersecurity?' },
      { id: 2, text: 'How does IP addressing work in networking?' },
      { id: 3, text: 'What are the common network protocols used in security?' },
    ];
  
    return (
      <div style={containerStyle}>
        <h2 style={headingStyle}>Doubt Questions</h2>
        <div style={questionContainerStyle}>
          {questions.map((question) => (
            <div
              key={question.id}
              style={questionStyle}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow = '0 6px 20px rgba(0, 0, 0, 0.15)';
                e.currentTarget.style.transform = 'translateY(-5px)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 15px rgba(0, 0, 0, 0.05)';
                e.currentTarget.style.transform = 'translateY(0)';
              }}
            >
              <p style={questionTextStyle}>{question.text}</p>
              <a
                href={`/questions/${question.id}`}
                style={buttonStyle}
                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#0056b3')}
                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#0069d9')}
              >
                View Discussion
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default Questions;
  