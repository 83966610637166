import React, { useState } from 'react';

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handlePasswordChange = (e) => {
        e.preventDefault();

        // Clear previous messages
        setError('');
        setSuccess('');

        // Validation
        if (!oldPassword || !newPassword || !confirmPassword) {
            setError('All fields are required.');
            return;
        }
        if (newPassword.length < 6) {
            setError('New password must be at least 6 characters long.');
            return;
        }
        if (newPassword !== confirmPassword) {
            setError('New password and confirmation do not match.');
            return;
        }

        // Simulate password change API call
        setTimeout(() => {
            setSuccess('Password changed successfully!');
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
        }, 1000);
    };

    return (
        <div style={styles.wrapper}>
            <div style={styles.container}>
                <div style={styles.circle}></div>
                <h2 style={styles.heading}>Change Your Password</h2>
                <p style={styles.description}>
                    Secure your account by updating your password regularly.
                </p>
                <form onSubmit={handlePasswordChange} style={styles.form}>
                    <div style={styles.formGroup}>
                        <label style={styles.label} htmlFor="oldPassword">Old Password</label>
                        <input
                            id="oldPassword"
                            type="password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            style={styles.input}
                            placeholder="Enter your old password"
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label} htmlFor="newPassword">New Password</label>
                        <input
                            id="newPassword"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            style={styles.input}
                            placeholder="Enter your new password (6+ characters)"
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label} htmlFor="confirmPassword">Confirm New Password</label>
                        <input
                            id="confirmPassword"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            style={styles.input}
                            placeholder="Re-enter your new password"
                        />
                    </div>
                    {error && <p style={styles.error}>{error}</p>}
                    {success && <p style={styles.success}>{success}</p>}
                    <button type="submit" style={styles.button}>
                        Update Password
                    </button>
                </form>
            </div>
        </div>
    );
};

const styles = {
    wrapper: {
        background: 'linear-gradient(to bottom right, #ff9a9e, #fad0c4, #fbc2eb)',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        animation: 'gradient 6s infinite ease-in-out alternate',
        padding: '20px',
    },
    container: {
        width: '100%',
        maxWidth: '450px',
        backgroundColor: 'white',
        padding: '40px',
        borderRadius: '15px',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.3)',
        textAlign: 'center',
        position: 'relative',
    },
    circle: {
        position: 'absolute',
        width: '150px',
        height: '150px',
        backgroundColor: '#ff9a9e',
        borderRadius: '50%',
        top: '-50px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: '-1',
    },
    heading: {
        fontSize: '1.8em',
        color: '#343a40',
        marginBottom: '15px',
        fontWeight: 'bold',
    },
    description: {
        fontSize: '1em',
        color: '#777',
        marginBottom: '20px',
    },
    form: {
        textAlign: 'left',
    },
    formGroup: {
        marginBottom: '15px',
    },
    label: {
        fontSize: '1em',
        fontWeight: '600',
        color: '#555',
        display: 'block',
        marginBottom: '5px',
    },
    input: {
        width: '100%',
        padding: '12px',
        fontSize: '1em',
        borderRadius: '8px',
        border: '1px solid #ddd',
        outline: 'none',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.2s ease-in-out',
    },
    inputFocus: {
        border: '1px solid #007bff',
    },
    button: {
        width: '100%',
        padding: '12px',
        fontSize: '1em',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '30px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease-in-out, transform 0.2s ease',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
    buttonHover: {
        backgroundColor: '#0056b3',
    },
    error: {
        color: 'red',
        fontSize: '0.9em',
        marginTop: '10px',
    },
    success: {
        color: 'green',
        fontSize: '0.9em',
        marginTop: '10px',
    },
};

export default ChangePassword;
