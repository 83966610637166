import React, { useState } from 'react';
import './App.css';
import Header from './Header';
import WelcomeBanner from './WelcomeBanner';
import ActiveCourses from './ActiveCourses';
import Assignments from './Assignment';
import Progress from './Progress';
import Questions from './Questions';
import Settings from './Settings';
import ChangePassword from './ChangePassword'; // New Component
import Logout from './Logout'; // New Component

function LMSApp() {
  const [selectedItem, setSelectedItem] = useState('Dashboard');
  const [selectedSubItem, setSelectedSubItem] = useState('');

  const appStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: '#f4f4f9',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
  };

  const headerStyle = {
    backgroundColor: '#007bff',
    padding: '10px 20px',
    color: 'white',
    textAlign: 'center',
    fontSize: '1.5em',
  };

  const sidebarStyle = {
    width: '250px',
    backgroundColor: '#343a40',
    color: 'white',
    paddingTop: '20px',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingRight: '10px',
  };

  const sidebarItemStyle = {
    margin: '15px 0',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '1.1em',
  };

  const subMenuStyle = {
    marginLeft: '20px',
    fontSize: '0.9em',
    cursor: 'pointer',
    color: '#d1d1d1',
  };

  const mainContainerStyle = {
    marginLeft: '250px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
  };

  const mainStyle = {
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    marginBottom: '20px',
  };

  const renderContent = () => {
    if (selectedItem === 'Settings' && selectedSubItem) {
      switch (selectedSubItem) {
        case 'Change Password':
          return <ChangePassword />;
        case 'Logout':
          return <Logout />;
        default:
          return <Settings />;
      }
    }

    switch (selectedItem) {
      case 'Dashboard':
        return <WelcomeBanner />;
      case 'Courses':
        return <ActiveCourses />;
      case 'Assignments':
        return <Assignments />;
      case 'Progress':
        return <Progress />;
      case 'Questions':
        return <Questions />;
      case 'Settings':
        return <Settings />;
      default:
        return <div>Select an option from the sidebar.</div>;
    }
  };

  return (
    <div className="App" style={appStyle}>
      <div style={headerStyle}>
        <Header />
      </div>

      <div style={{ display: 'flex' }}>
        {/* Sidebar */}
        <div style={sidebarStyle}>
          {['Dashboard', 'Courses', 'Assignments', 'Progress', 'Questions', 'Settings'].map((item) => (
            <div
              key={item}
              style={sidebarItemStyle}
              onClick={() => {
                setSelectedItem(item);
                setSelectedSubItem(''); // Reset sub-item when switching main menu
              }}
            >
              {item}

              {/* Sub-menu for "Settings" */}
              {item === 'Settings' && selectedItem === 'Settings' && (
                <div>
                  <div
                    style={subMenuStyle}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the parent click
                      setSelectedSubItem('Change Password');
                    }}
                  >
                    Change Password
                  </div>
                  <div
                    style={subMenuStyle}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the parent click
                      setSelectedSubItem('Logout');
                    }}
                  >
                    Logout
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Main Content */}
        <div style={mainContainerStyle}>
          <div style={mainStyle}>{renderContent()}</div>
        </div>
      </div>
    </div>
  );
}

export default LMSApp;
