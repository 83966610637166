import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CourseModal from '../Modal/Test';
import farmerImage from '../assets/header.webp';

const courses = [
  { id: 1, name: 'Certificate Course in Structured Query Language using MySQL', description: 'Learn the fundamentals of SQL and how to manage databases effectively.', price: 'Free', category: 'SQL' },
  { id: 2, name: 'Introduction to Python', description: 'Get started with Python programming, covering basic concepts and practical applications.', price: 'Free', category: 'Python' },
  { id: 3, name: 'Certificate Course in Basic Python', description: 'A comprehensive course to build your Python skills from scratch.', price: 'Free', category: 'Python' },
  { id: 4, name: 'Advanced Program in Cyber Security', description: 'Deep dive into advanced concepts in Cyber Security and threat management.', price: '4590', category: 'Cyber Security' },
  { id: 5, name: 'Certificate Course in Linux Server Administration', description: 'Gain expertise in Linux server management and administration.', price: '850', category: 'Linux' },
  { id: 6, name: 'Certificate Course in Cyber Security', description: 'Understand the principles of Cyber Security and how to implement them effectively.', price: '1550', category: 'Cyber Security' },
  { id: 7, name: 'AWS Certification Course', description: 'Master Amazon Web Services and get certified in cloud computing and cloud architecture.', price: '950', category: 'AWS' },
  { id: 8, name: 'HTML Certification Course', description: 'Learn HTML, the foundational language of web development, and get certified in building websites.', price: 'Free', category: 'Web Development' },
  { id: 9, name: 'Javascript Certification Course', description: 'Become proficient in JavaScript programming and enhance your web development skills.', price: 'Free', category: 'Web Development' },
  { id: 10, name: 'Basic AI Certification Course', description: 'Learn the basics of Artificial Intelligence and its applications in the real world.', price: 'Free', category: 'AI' },
  { id: 11, name: 'ReactJS Certification Course', description: 'Master ReactJS to build interactive user interfaces and dynamic web applications.', price: 'Free', category: 'Web Development' },
  { id: 12, name: 'NodeJS Certification Course', description: 'Learn how to build server-side applications using Node.js, a powerful backend framework.', price: 'Free', category: 'Web Development' },
  { id: 13, name: 'React Native Certification Course', description: 'Get certified in building cross-platform mobile applications using React Native.', price: '650', category: 'Mobile Development' },
  { id: 14, name: 'AngularJS Certification Course', description: 'Learn AngularJS to create dynamic, single-page applications with ease.', price: 'Free', category: 'Web Development' },
  { id: 15, name: 'TypeScript Certification Course', description: 'Understand the power of TypeScript to write clean, maintainable, and scalable JavaScript applications.', price: 'Free', category: 'Web Development' },
  { id: 16, name: 'C Program Certification Course', description: 'Master the C programming language and become proficient in developing software applications and system-level programming.', price: 'Free', category: 'Programming' },
];

function Home() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const navigate = useNavigate(); // For navigation

  const openModal = (course) => {
    setSelectedCourse(course);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedCourse(null);
  };

  const handleLoginClick = () => {
    navigate('/login'); // Redirect to login page
  };
  
  const handleFacultyClick = () => {
    navigate('/Faculty'); // Redirect to Faculty page
  };

  const handleEventClick = () => {
    navigate('/Event'); // Redirect to Faculty page
  };

  return (
    <>
      {/* Inline CSS */}
      <style>
        {`
           body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
            .header {
      display: flex;
      justify-content: space-between; /* This keeps the logo and button container spaced */
      align-items: center;
      padding: 10px 20px;
      background: #007bff;
      color: #fff;
    }
              .header .logo {
      font-size: 1.5rem;
      font-weight: bold;
    }
  .button-container {
      display: flex;
      gap: 15px; /* Space between buttons */
      justify-content: flex-end; /* Align the buttons to the right */
    }
          .header .login-button {
            background: #ff6600;
            color: #fff;
            padding: 8px 15px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background 0.3s ease;
          }
          .header .login-button:hover {
            background: #cc5200;
          }
 .header button {
      background: #ff6600;
      color: #fff;
      padding: 8px 15px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background 0.3s ease;
    }

          .header .Faculty-button {
            background: #ff6600;
            color: #fff;
            padding: 8px 15px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background 0.3s ease;
          }
          .header .Faculty-button:hover {
            background: #cc5200;
          }
          .hero-section {
            position: relative;
            width: 100%;
            height: 50vh;
            background: #f5f5f5;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
          }
          .hero-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }
          .hero-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            z-index: 2;
          }
          .hero-content {
            position: relative;
            z-index: 3;
            color: #fff;
            text-align: center;
            max-width: 600px;
            padding: 20px;
          }
          .hero-content h1 {
            font-size: 2.5rem;
            margin-bottom: 10px;
          }
          .hero-content p {
            font-size: 1.2rem;
            margin-bottom: 20px;
          }
          .cta-button {
            background: #ff6600;
            color: #fff;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            font-size: 1rem;
            cursor: pointer;
            transition: background 0.3s ease;
          }
          .cta-button:hover {
            background: #cc5200;
          }
          .courses-section {
            padding: 40px 20px;
            background: #f9f9f9;
            text-align: center;
          }
          .courses-section h2 {
            font-size: 2rem;
            margin-bottom: 10px;
          }
          .section-description {
            font-size: 1.1rem;
            color: #666;
            margin-bottom: 30px;
          }
          .course-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 20px;
          }
          .course-card {
            background: #fff;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease, box-shadow 0.3s ease;
          }
          .course-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          }
          .course-card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .course-card h3 {
            font-size: 1.2rem;
            margin: 0;
          }
          .category {
            background: #ff6600;
            color: #fff;
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 0.8rem;
          }
          .description {
            color: #555;
            margin: 10px 0;
          }
          .price {
            font-weight: bold;
            color: #ff6600;
            margin: 10px 0;
          }
          .enroll-button {
            background: #007bff;
            color: #fff;
            padding: 10px 15px;
            border: none;
            border-radius: 5px;
            font-size: 0.9rem;
            cursor: pointer;
            transition: background 0.3s ease;
             margin-right: 10px;
          }
          .enroll-button:hover {
            background: #0056b3;
          }
               .header button:hover {
      background: #cc5200;
    }
      .footer {
  padding: 20px;
  background-color: #333;
  color: white;
  text-align: center;
  font-size: 0.9rem;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 30px; /* Add space between the links */
  margin-bottom: 20px; /* Add space above the social icons */
}

.footer-link {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #fff;
}

.social-icons {
  margin-bottom: 20px;
}

.social-icons i {
  color: #fff;
  margin: 0 10px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.social-icons i:hover {
  color: #ff6600;
}

.footer-copyright p {
  margin-top: 20px;
  color: #aaa;
}

        `}
      </style>

      <div className="course-page">
        {/* Header Section */}
        <header className="header">
    <div className="logo">EduPlatform</div>
    <div className="button-container">
      <button className="login-button" onClick={handleLoginClick}>
        Login
      </button>
      <button className="Faculty-button" onClick={handleFacultyClick}>
        Faculty
      </button>
      <button className="Event-button" onClick={handleEventClick}>
        Event
      </button>
    </div>
  </header>
        {/* Hero Section */}
        <div className="hero-section">
          <img src={farmerImage} alt="Farmer supporting bamboo products" className="hero-image" />
          <div className="hero-overlay">
            <div className="hero-content">
              <h1>Learn with Us</h1>
              <p>Discover your potential with our expert-curated courses.</p>
              <button className="cta-button">Explore Courses</button>
            </div>
          </div>
        </div>

        {/* Courses Section */}
        <section className="courses-section">
          <h2>Our Courses</h2>
          <p className="section-description">Browse our wide range of free courses and start learning today!</p>
          <div className="course-grid">
            {courses.map((course) => (
              <div key={course.id} className="course-card">
                <div className="course-card-header">
                  <h3>{course.name}</h3>
                  <p className="category">{course.category}</p>
                </div>
                <p className="description">{course.description}</p>
                <p className="price">{course.price}</p>
                <button className="enroll-button" onClick={() => openModal(course)}>
                  Enroll Now
                </button>
                <button
                  className="enroll-button"
                  onClick={() => navigate(`/course/${course.id}`)}
                >
                  Course Details
                </button>
              </div>
            ))}
          </div>
        </section>
  {/* Footer Section */}
  <footer className="footer">
  <div className="footer-links">
    <a href="CancellationAndRefundPolicy" className="footer-link">Refund Policy</a>
    <a href="Contact" className="footer-link">Contact Us</a>
    <a href="TermsAndConditions" className="footer-link">Terms & Conditions</a>
  </div>

  <div className="social-icons">
    <i className="fa fa-facebook"></i>
    <i className="fa fa-twitter"></i>
    <i className="fa fa-instagram"></i>
    <i className="fa fa-linkedin"></i>
  </div>

  <div className="footer-copyright">
    <p>&copy; 2024 Codevocado. All Rights Reserved.</p>
  </div>
</footer>
        {/* Modal */}
        <CourseModal
          isOpen={modalOpen}
          onClose={closeModal}
          courseName={selectedCourse ? selectedCourse.name : ''}
        />
      </div>
    </>
  );
}

export default Home;
