import React from 'react';
import './Contact.css'; // Ensure this CSS file exists in the same directory

function ContactAs() {
  return (
    <div className="contact-page">
      <div className="contact-header">
        <h1>Contact Us</h1>
        <p>We re here to help! Reach out using the information below.</p>
      </div>

      <div className="contact-container">
        {/* Contact Information */}
        <div className="contact-info">
          <h2>Contact Details</h2>
          <p><strong>Last Updated:</strong> 20-11-2024 18:38:11</p>
          <p><strong>Merchant Legal Entity Name:</strong> Codevocado</p>
          <p><strong>Registered Address:</strong> 18 Deubari, Deoria, Uttar Pradesh, PIN: 274604</p>
          <p><strong>Operational Address:</strong> 18 Deubari, Deoria, Uttar Pradesh, PIN: 274604</p>
          <p><strong>Telephone No:</strong> 7007643084</p>
          <p><strong>E-Mail ID:</strong> <a href="mailto:ceo.upadhyayinfotech@gmail.co">ceo.upadhyayinfotech@gmail.co</a></p>
        </div>

        {/* Contact Form */}
        <div className="contact-form">
          <h2>Send Us a Message</h2>
          <form>
            <label htmlFor="name">Full Name</label>
            <input type="text" id="name" name="name" placeholder="Enter your full name" required />

            <label htmlFor="email">Email Address</label>
            <input type="email" id="email" name="email" placeholder="Enter your email address" required />

            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" rows="5" placeholder="Type your message here" required></textarea>

            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactAs;
