import React from 'react';

function Header() {
  return (
    <header className="header">
      <div className="logo">LMS</div>
      <div className="profile">
        <img src="avatar.png" alt="User Profile" className="avatar" />
        <div className="user-name">John Doe</div>
        <div className="dropdown">
          <button>Account</button>
          <div className="dropdown-content">
            <a href="#">Settings</a>
            <a href="#">Logout</a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
