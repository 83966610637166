import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './test.css'; // Import CSS for modal styles

// Configuration variables (use environment variables in production)
const apiBaseUrl = process.env.REACT_APP_COURSE_URL || "http://localhost:5000";
const phonepeUPI = process.env.REACT_APP_PHONEPE_UPI_ID || "merchant@upi";
const phonepeMerchantName = process.env.REACT_APP_PHONEPE_MERCHANT_NAME || "CoursePlatform";
const phonepeCallbackUrl = process.env.REACT_APP_PHONEPE_CALLBACK_URL || "https://your-callback-url.com";
const paymentAmount = process.env.REACT_APP_PHONEPE_PAYMENT_AMOUNT || 108;

const Test = ({ isOpen, onClose, courseName }) => {
  const navigate = useNavigate(); // For navigation
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    jobRole: '',
    comment: '',
  });

  const [error, setError] = useState(''); // To display errors
  const [isLoading, setIsLoading] = useState(false); // Loading indicator

  // Save course name in session storage
  if (!sessionStorage.getItem('examName')) {
    sessionStorage.setItem('examName', courseName);
  }

  // Validate form inputs
  const validateForm = () => {
    const { name, email, mobile, jobRole, comment } = formData;
    if (!name || !email || !mobile || !jobRole || !comment) {
      setError("All fields are required.");
      return false;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setError("Please enter a valid email address.");
      return false;
    }
    if (!/^\d{10}$/.test(mobile)) {
      setError("Please enter a valid 10-digit mobile number.");
      return false;
    }
    return true;
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setError('');
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }
  
    setIsLoading(true);
    setError('');
    try {
      // Step 1: Save user data via API call
      const response = await fetch(`${apiBaseUrl}/add_course_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to save data.');
      }
  
      const result = await response.json();
      if (result.status) {
        // Step 2: Prepare and send request to initiate payment
        const transactionId = `TXN${Date.now()}`; // Unique transaction ID
        const paymentData = {
          transactionId,
          name: formData.name,
          amount: 108, // Payment amount
          phone: formData.mobile,
        };
  
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
  
        const raw = JSON.stringify(paymentData);
  
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
  
        const paymentResponse = await fetch("http://localhost:5000/api/initiate-payment", requestOptions);
        const paymentResult = await paymentResponse.json(); // Parse as JSON
  
        // Log the entire payment result for debugging purposes
        console.log("Payment API Response:", paymentResult);
  
        if (paymentResponse.ok && paymentResult.success) {
          // Safely access the redirect URL in instrumentResponse
          const redirectInfo = paymentResult.data?.instrumentResponse?.redirectInfo;
  
          if (redirectInfo && redirectInfo.url) {
            // Redirect the user to the PhonePe payment page
            window.location.href = redirectInfo.url;
          } else {
            throw new Error('Payment redirection URL is missing or undefined.');
          }
        } else {
          throw new Error(paymentResult.message || 'Payment initiation failed.');
        }
      } else {
        throw new Error(result.message || 'Unknown error occurred.');
      }
    } catch (error) {
      setError(error.message || 'Failed to initiate payment.');
    } finally {
      setIsLoading(false);
    }
  };
  
  

  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Enroll in {courseName}</h2>
        {error && <div className="error-message">{error}</div>}
        {isLoading && <div className="loading">Processing...</div>}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="tel"
            name="mobile"
            placeholder="Your Mobile Number"
            value={formData.mobile}
            onChange={handleChange}
            required
          />
          <select name="jobRole" value={formData.jobRole} onChange={handleChange} required>
            <option value="" disabled>
              Select your Job Role
            </option>
            <option value="Software Engineer">Software Engineer</option>
            <option value="Student">Student</option>
            <option value="Other">Other</option>
          </select>
          <textarea
            name="comment"
            placeholder="Why are you taking this course?"
            value={formData.comment}
            onChange={handleChange}
            required
          />
          <button type="submit" disabled={isLoading}>Enroll</button>
        </form>
        <button className="close-button" onClick={onClose} disabled={isLoading}>
          Close
        </button>
      </div>
    </div>
  );
};

export default Test;
