const Progress = () => {
    const containerStyle = {
      padding: '30px 40px',
      backgroundColor: '#f9f9f9',
      borderRadius: '12px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      maxWidth: '800px',
      margin: 'auto',
      marginTop: '30px',
    };
  
    const headingStyle = {
      fontSize: '2.5em',
      fontWeight: '700',
      color: '#2c3e50',
      textAlign: 'center',
      marginBottom: '20px',
    };
  
    const subHeadingStyle = {
      fontSize: '1.2em',
      color: '#7f8c8d',
      marginBottom: '20px',
      textAlign: 'center',
    };
  
    const progressContainerStyle = {
      marginBottom: '20px',
      padding: '15px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    };
  
    const progressBarStyle = {
      height: '20px',
      borderRadius: '8px',
      overflow: 'hidden',
    };
  
    const progressBarFillStyle = (percentage) => ({
      width: `${percentage}%`,
      backgroundColor: percentage >= 70 ? '#27ae60' : percentage >= 40 ? '#f39c12' : '#e74c3c',
      height: '100%',
      textAlign: 'center',
      lineHeight: '20px',
      color: '#fff',
      fontWeight: 'bold',
    });
  
    const courseTitleStyle = {
      fontSize: '1.5em',
      fontWeight: '600',
      color: '#2c3e50',
      marginBottom: '10px',
    };
  
    const courses = [
      { title: 'Cyber Security Basics', progress: 70 },
      { title: 'Networking Essentials', progress: 40 },
    ];
  
    return (
      <div style={containerStyle}>
        <h2 style={headingStyle}>Your Learning Progress</h2>
        <p style={subHeadingStyle}>Track your learning journey below:</p>
  
        {courses.map((course, index) => (
          <div key={index} style={progressContainerStyle}>
            <div style={courseTitleStyle}>{course.title}</div>
            <div style={progressBarStyle}>
              <div
                style={progressBarFillStyle(course.progress)}
              >
                {course.progress}%
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default Progress;
  