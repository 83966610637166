import React, { useState } from 'react';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the login logic here (e.g., authentication)
    console.log('Logging in with', username, password);
  };

  const styles = {
    loginPage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f2f3f7',
      fontFamily: 'Roboto, sans-serif',
    },
    form: {
      background: 'linear-gradient(135deg, #f5f5f5, #ffffff)', // Light gradient background
      padding: '40px',
      borderRadius: '10px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      width: '350px',
      maxWidth: '100%',
      textAlign: 'center',
    },
    formGroup: {
      marginBottom: '20px',
    },
    label: {
      display: 'block',
      marginBottom: '8px',
      fontWeight: '600',
      color: '#333',
    },
    input: {
      width: '100%',
      padding: '12px 15px',
      margin: '10px 0',
      border: '1px solid #ddd',
      borderRadius: '5px',
      fontSize: '16px',
      boxSizing: 'border-box',
      transition: 'border-color 0.3s ease',
    },
    inputFocus: {
      borderColor: '#4A90E2',
      outline: 'none',
    },
    button: {
      width: '100%',
      padding: '12px',
      backgroundColor: '#4A90E2',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      fontSize: '16px',
      transition: 'background-color 0.3s ease',
    },
    buttonHover: {
      backgroundColor: '#357ABD',
    },
    buttonActive: {
      backgroundColor: '#2C6EA1',
    },
    heading: {
      marginBottom: '30px',
      fontSize: '24px',
      fontWeight: '700',
      color: '#333',
    },
    forgotPassword: {
      marginTop: '15px',
      fontSize: '14px',
      color: '#4A90E2',
      textDecoration: 'none',
      transition: 'color 0.2s ease',
    },
    forgotPasswordHover: {
      color: '#357ABD',
    }
  };

  return (
    <div style={styles.loginPage}>
      <div style={styles.form}>
        <h2 style={styles.heading}>Login</h2>
        <form onSubmit={handleSubmit}>
          <div style={styles.formGroup}>
            <label htmlFor="username" style={styles.label}>Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              style={styles.input}
              onFocus={(e) => e.target.style.borderColor = styles.inputFocus.borderColor}
              onBlur={(e) => e.target.style.borderColor = '#ddd'}
            />
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="password" style={styles.label}>Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={styles.input}
              onFocus={(e) => e.target.style.borderColor = styles.inputFocus.borderColor}
              onBlur={(e) => e.target.style.borderColor = '#ddd'}
            />
          </div>
          <button
            type="submit"
            style={styles.button}
            onMouseOver={(e) => e.target.style.backgroundColor = styles.buttonHover.backgroundColor}
            onMouseOut={(e) => e.target.style.backgroundColor = styles.button.backgroundColor}
            onMouseDown={(e) => e.target.style.backgroundColor = styles.buttonActive.backgroundColor}
            onMouseUp={(e) => e.target.style.backgroundColor = styles.buttonHover.backgroundColor}
          >
            Login
          </button>
        </form>
        <a
          href="#"
          style={styles.forgotPassword}
          onMouseOver={(e) => e.target.style.color = styles.forgotPasswordHover.color}
          onMouseOut={(e) => e.target.style.color = styles.forgotPassword.color}
        >
          Forgot Password?
        </a>
      </div>
    </div>
  );
}

export default Login;
