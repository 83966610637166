import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

function ActiveCourses() {
  return (
    <section className="active-courses" style={sectionStyle}>
      <h2 style={headerStyle}>Your Active Certification Courses</h2>
      <div className="course-cards" style={cardsContainerStyle}>
        {/* Course Card 1 */}
        <Link to="/coursevideos" style={linkStyle}>
          <div className="course-card" style={cardStyle}>
            <h3 style={courseTitleStyle}>Course 1: Networking Certification</h3>
            <div className="progress-bar" style={progressBarContainerStyle}>
              <span style={progressTextStyle}>30% Complete</span>
              <div className="progress" style={progressBarStyle}>
                <div className="progress-fill" style={{ ...progressFillStyle, width: '50%' }}></div>
              </div>
            </div>
            <button className="action-btn" style={buttonStyle}>Resume Course</button>
            <button className="action-btn" style={buttonStyle}>View Details</button>
          </div>
        </Link>

        {/* Course Card 2 */}
        <Link to="/course/2" style={linkStyle}>
          <div className="course-card" style={cardStyle}>
            <h3 style={courseTitleStyle}>Course 2: CyberSecurity</h3>
            <div className="progress-bar" style={progressBarContainerStyle}>
              <span style={progressTextStyle}>30% Complete</span>
              <div className="progress" style={progressBarStyle}>
                <div className="progress-fill" style={{ ...progressFillStyle, width: '30%' }}></div>
              </div>
            </div>
            <button className="action-btn" style={buttonStyle}>Resume Course</button>
            <button className="action-btn" style={buttonStyle}>View Details</button>
          </div>
        </Link>

        {/* Course Card 3 */}
        <Link to="/course/3" style={linkStyle}>
          <div className="course-card" style={cardStyle}>
            <h3 style={courseTitleStyle}>Course 3: AWS Certification</h3>
            <div className="progress-bar" style={progressBarContainerStyle}>
              <span style={progressTextStyle}>30% Complete</span>
              <div className="progress" style={progressBarStyle}>
                <div className="progress-fill" style={{ ...progressFillStyle, width: '30%' }}></div>
              </div>
            </div>
            <button className="action-btn" style={buttonStyle}>Resume Course</button>
            <button className="action-btn" style={buttonStyle}>View Details</button>
          </div>
        </Link>
      </div>
    </section>
  );
}

// Inline styles for the component
const sectionStyle = {
  backgroundColor: '#ffffff',
  padding: '30px',
  borderRadius: '10px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  marginBottom: '30px',
};

const headerStyle = {
  fontSize: '1.8em',
  color: '#333',
  marginBottom: '20px',
  fontWeight: 'bold',
};

const cardsContainerStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px',
  justifyContent: 'space-between',
};

const cardStyle = {
  backgroundColor: '#f9f9f9',
  padding: '20px',
  borderRadius: '8px',
  width: '48%',
  boxSizing: 'border-box',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  minHeight: '250px',
};

const courseTitleStyle = {
  fontSize: '1.5em',
  color: '#333',
  marginBottom: '15px',
  fontWeight: 'bold',
};

const progressBarContainerStyle = {
  width: '100%',
  backgroundColor: '#f3f3f3',
  padding: '8px',
  borderRadius: '5px',
  marginBottom: '15px',
};

const progressTextStyle = {
  fontSize: '1.1em',
  color: '#333',
  marginBottom: '10px',
  display: 'block',
};

const progressBarStyle = {
  width: '100%',
  backgroundColor: '#e0e0e0',
  borderRadius: '5px',
  height: '12px',
  overflow: 'hidden',
};

const progressFillStyle = {
  height: '100%',
  backgroundColor: '#4caf50',
  borderRadius: '5px',
};

const buttonStyle = {
  backgroundColor: '#007bff',
  color: 'white',
  border: 'none',
  padding: '10px 15px',
  borderRadius: '5px',
  fontSize: '1em',
  marginTop: '10px',
  width: '100%',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
};

const linkStyle = {
  textDecoration: 'none', // Remove default link styles
};

export default ActiveCourses;
