const Assignments = () => {
    const containerStyle = {
      padding: '40px 20px',
      backgroundColor: '#eef2f7',
      borderRadius: '12px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      marginTop: '30px',
      width: '80%',
      margin: 'auto',
      maxWidth: '1200px',
    };
  
    const headingStyle = {
      fontSize: '2.5em',
      fontWeight: '700',
      color: '#333',
      textAlign: 'center',
      marginBottom: '20px',
      letterSpacing: '1.5px',
    };
  
    const assignmentStyle = {
      display: 'flex',
      flexDirection: 'row',
      padding: '20px',
      marginBottom: '15px',
      borderRadius: '8px',
      backgroundColor: '#fff',
      boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      cursor: 'pointer',
      borderLeft: '8px solid #3498db',
    };
  
    const assignmentInfoStyle = {
      flex: 1,
      marginLeft: '15px',
    };
  
    const assignmentTitleStyle = {
      fontSize: '1.6em',
      fontWeight: '600',
      color: '#2c3e50',
      marginBottom: '10px',
    };
  
    const deadlineStyle = {
      fontSize: '1.1em',
      color: '#7f8c8d',
      marginBottom: '12px',
    };
  
    const viewDetailsStyle = {
      padding: '10px 20px',
      backgroundColor: '#3498db',
      color: '#fff',
      fontSize: '1.1em',
      fontWeight: '600',
      textDecoration: 'none',
      borderRadius: '25px',
      display: 'inline-block',
      marginTop: '10px',
      transition: 'background-color 0.3s ease, transform 0.3s ease',
    };
  
    const assignments = [
      { id: 1, title: 'Cyber Security Homework', deadline: '2024-12-10' },
      { id: 2, title: 'Networking Quiz', deadline: '2024-12-15' },
      { id: 3, title: 'Cloud Computing Project', deadline: '2024-12-20' },
      { id: 4, title: 'Firewall Configuration Lab', deadline: '2024-12-18' },
    ];
  
    return (
      <div style={containerStyle}>
        <h2 style={headingStyle}>Assignments</h2>
        {assignments.map(assignment => (
          <div
            key={assignment.id}
            style={assignmentStyle}
            onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
            onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <div style={assignmentInfoStyle}>
              <h3 style={assignmentTitleStyle}>{assignment.title}</h3>
              <p style={deadlineStyle}>Deadline: {assignment.deadline}</p>
              <a 
                href={`/assignments/${assignment.id}`} 
                style={viewDetailsStyle}
                onMouseOver={(e) => e.target.style.backgroundColor = '#2980b9'}
                onMouseOut={(e) => e.target.style.backgroundColor = '#3498db'}
              >
                View Details
              </a>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default Assignments;
  