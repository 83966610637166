import React from 'react';
import { FaEnvelope } from 'react-icons/fa';  // For the email icon
import profileImage from '../assets/photo_2024-07-04_15-29-44.jpg'; // Correct import

function FacultyPage() {
  const facultyMembers = [
    {
      id: 1,
      name: 'Mr. Raghavendra Upadhyay',
      designation: 'Professor of Computer Science',
      bio: 'Dr. John has over 20 years of experience in software engineering and has worked with several leading tech companies.',
      image: profileImage // Use the imported image directly
    },
    {
      id: 2,
      name: 'Prof. Shalini Singh',
      designation: 'Assistant Professor of Data Science',
      bio: 'Prof. Jane specializes in artificial intelligence and machine learning, with numerous research papers in top journals.',
      image: 'https://via.placeholder.com/150'
    },
    {
      id: 3,
      name: 'Mr. Samuel Harris',
      designation: 'Lecturer in Web Development',
      bio: 'Mr. Samuel has a passion for web development and has worked on various real-world projects involving React and Node.js.',
      image: 'https://via.placeholder.com/150'
    }
  ];

  return (
    <div className="faculty-page">
      {/* Inline CSS styles */}
      <style>
        {`
          body {
            font-family: 'Poppins', sans-serif;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            background-color: #f8f9fa;
          }
          .faculty-page {
            padding: 40px 20px;
            max-width: 1200px;
            margin: auto;
            background-color: #f8f9fa;
          }
          .faculty-page h1 {
            text-align: center;
            font-size: 3rem;
            font-weight: 700;
            color: #2c3e50;
            margin-bottom: 50px;
            text-transform: uppercase;
            letter-spacing: 2px;
            background-image: linear-gradient(to right, #f39c12, #e74c3c);
            -webkit-background-clip: text;
            color: transparent;
            animation: fadeIn 1s ease-out;
          }
          @keyframes fadeIn {
            0% { opacity: 0; transform: translateY(50px); }
            100% { opacity: 1; transform: translateY(0); }
          }
          .faculty-container {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
            gap: 30px;
            justify-items: center;
            margin-bottom: 50px;
            animation: fadeInUp 1s ease-out;
          }
          @keyframes fadeInUp {
            0% { opacity: 0; transform: translateY(50px); }
            100% { opacity: 1; transform: translateY(0); }
          }
          .faculty-card {
            background-color: #fff;
            border-radius: 15px;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            text-align: center;
            position: relative;
            padding: 30px;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            background: linear-gradient(135deg, #fbc2eb 10%, #a6c1ee 100%);
          }
          .faculty-card:hover {
            transform: translateY(-10px);
            box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
            background: linear-gradient(135deg, #ff6600 10%, #ff3d00 100%);
          }
          .faculty-card img {
            width: 150px;
            height: 150px;
            object-fit: cover;
            border-radius: 50%;
            border: 5px solid #fff;
            margin-top: -80px;
            box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease;
          }
          .faculty-card img:hover {
            transform: scale(1.1);
          }
          .faculty-card-body {
            padding-top: 10px;
          }
          .faculty-card h3 {
            font-size: 1.8rem;
            color: #333;
            font-weight: 700;
            margin: 15px 0;
            transition: color 0.3s ease;
          }
          .faculty-card h3:hover {
            color: #ff6600;
          }
          .faculty-card p {
            font-size: 1.1rem;
            color: #666;
            margin: 5px 0;
            font-weight: 400;
            transition: color 0.3s ease;
          }
          .faculty-card p:hover {
            color: #ff6600;
          }
          .faculty-card .bio {
            font-size: 1rem;
            color: #555;
            margin-top: 10px;
            padding: 0 10px;
            text-align: justify;
            font-style: italic;
            opacity: 0.8;
          }
          .faculty-card-footer {
            position: absolute;
            bottom: 20px;
            width: 100%;
            text-align: center;
            padding: 10px 0;
            background-color: rgba(255, 255, 255, 0.8);
            border-top: 2px solid #f1f1f1;
            transition: background-color 0.3s ease;
          }
          .faculty-card-footer:hover {
            background-color: rgba(255, 255, 255, 1);
          }
          .faculty-card-footer a {
            text-decoration: none;
            color: #007bff;
            font-size: 1.1rem;
            font-weight: 600;
            transition: color 0.3s ease;
          }
          .faculty-card-footer a:hover {
            color: #ff6600;
          }
          .faculty-card-footer .icon {
            margin-right: 8px;
          }
        `}
      </style>

      <h1>Meet Our Vibrant Faculty</h1>
      <div className="faculty-container">
        {facultyMembers.map((faculty) => (
          <div key={faculty.id} className="faculty-card">
            <img src={faculty.image} alt={faculty.name} />
            <div className="faculty-card-body">
              <h3>{faculty.name}</h3>
              <p>{faculty.designation}</p>
              <p className="bio">{faculty.bio}</p>
            </div>
            <div className="faculty-card-footer">
              <a href={`mailto:${faculty.name.split(' ').join('.')}@university.com`}>
                <FaEnvelope className="icon" />
                Contact
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FacultyPage;
