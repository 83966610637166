import React from 'react';
import { FaCalendarAlt, FaInfoCircle } from 'react-icons/fa'; // Icons

function EventsPage() {
  const events = [
    {
      id: 1,
      title: 'Tech Talk: AI Innovations',
      date: '2024-12-10',
      description: 'Join us for a fascinating session on AI innovations by industry experts.',
      image: 'https://via.placeholder.com/300',
    },
    {
      id: 2,
      title: 'Workshop: Web Development Bootcamp',
      date: '2024-12-15',
      description: 'A hands-on workshop covering web development essentials like HTML, CSS, and JavaScript.',
      image: 'https://via.placeholder.com/300',
    },
    {
      id: 3,
      title: 'Seminar: Data Science and Machine Learning',
      date: '2024-12-20',
      description: 'Dive into the world of data science and machine learning with expert-led sessions.',
      image: 'https://via.placeholder.com/300',
    },
  ];

  return (
    <div className="events-page">
      <style>
        {`
          body {
            font-family: 'Poppins', sans-serif;
            margin: 0;
            padding: 0;
            background-color: #fafafa;
          }

          .events-page {
            padding: 50px 20px;
            max-width: 1200px;
            margin: auto;
            background-color: #fff;
            position: relative;
          }

          h1 {
            text-align: center;
            font-size: 3rem;
            font-weight: 700;
            color: #333;
            margin-bottom: 50px;
            text-transform: uppercase;
            letter-spacing: 3px;
            background-image: linear-gradient(to right, #f97316, #e44d26);
            -webkit-background-clip: text;
            color: transparent;
            animation: fadeIn 1s ease-out;
          }

          @keyframes fadeIn {
            0% { opacity: 0; transform: translateY(30px); }
            100% { opacity: 1; transform: translateY(0); }
          }

          .events-container {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
            gap: 40px;
            justify-items: center;
            animation: fadeInUp 1.2s ease-out;
          }

          @keyframes fadeInUp {
            0% { opacity: 0; transform: translateY(30px); }
            100% { opacity: 1; transform: translateY(0); }
          }

          .event-card {
            background-color: #fff;
            border-radius: 15px;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            text-align: center;
            position: relative;
            padding: 25px;
            transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 0.4s;
            perspective: 1000px;
          }

          .event-card:hover {
            transform: rotateY(10deg) rotateX(10deg) scale(1.05);
            box-shadow: 0 8px 40px rgba(0, 0, 0, 0.15);
            background-color: #fef2f2;
          }

          .event-card img {
            width: 100%;
            height: 250px;
            object-fit: cover;
            border-radius: 10px;
            margin-bottom: 20px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
            transition: transform 0.3s ease;
          }

          .event-card img:hover {
            transform: scale(1.1);
          }

          .event-card h3 {
            font-size: 1.8rem;
            color: #333;
            font-weight: 700;
            margin-bottom: 15px;
          }

          .event-card-footer a {
            text-decoration: none;
            color: #f97316;
            font-size: 1.2rem;
            font-weight: 600;
            transition: color 0.3s ease;
          }

          .event-card-footer a:hover {
            color: #e44d26;
          }

          /* Animated background */
          @keyframes gradientMove {
            0% {
              background: linear-gradient(45deg, #f97316, #e44d26);
            }
            50% {
              background: linear-gradient(45deg, #ff9f00, #ff4e00);
            }
            100% {
              background: linear-gradient(45deg, #f97316, #e44d26);
            }
          }

          .background-animated {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(45deg, #f97316, #e44d26);
            animation: gradientMove 5s ease infinite;
            z-index: -1;
          }
        `}
      </style>

      <div className="background-animated"></div> {/* Animated background */}

      <h1>Upcoming Events</h1>
      <div className="events-container">
        {events.map((event) => (
          <div key={event.id} className="event-card">
            <img src={event.image} alt={event.title} />
            <div className="event-card-body">
              <h3>{event.title}</h3>
              <p><FaCalendarAlt /> {new Date(event.date).toLocaleDateString()}</p>
              <p>{event.description}</p>
            </div>
            <div className="event-card-footer">
              <a href="#">
                <FaInfoCircle className="icon" />
                More Info
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EventsPage;
