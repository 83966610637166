import React, { useState } from 'react';

const Settings = () => {
  const [showModal, setShowModal] = useState(false);

  const containerStyle = {
    padding: '40px',
    backgroundColor: '#f7f7f7',
    borderRadius: '16px',
    boxShadow: '0 12px 30px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    margin: '50px auto',
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    textAlign: 'center',
  };

  const headingStyle = {
    fontSize: '2.5em',
    fontWeight: '700',
    color: '#333',
    marginBottom: '20px',
    letterSpacing: '-0.5px',
  };

  const inputContainerStyle = {
    marginBottom: '20px',
    textAlign: 'left',
    display: 'block',
  };

  const labelStyle = {
    fontSize: '1.1em',
    fontWeight: '500',
    color: '#666',
    display: 'block',
    marginBottom: '8px',
    textAlign: 'left',
    letterSpacing: '0.5px',
  };

  const inputStyle = {
    display: 'block',
    padding: '16px 20px',
    width: '100%',
    borderRadius: '12px',
    border: '1px solid #ddd',
    backgroundColor: '#fff',
    fontSize: '1em',
    marginBottom: '15px',
    transition: 'border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    boxSizing: 'border-box',
  };

  const buttonStyle = {
    padding: '14px 30px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '30px',
    fontSize: '1.1em',
    fontWeight: '600',
    cursor: 'pointer',
    letterSpacing: '0.5px',
    transition: 'all 0.3s ease',
    width: '100%',
    marginTop: '20px',
  };

  const resetButtonStyle = {
    padding: '14px 30px',
    backgroundColor: '#dc3545',
    color: '#fff',
    border: 'none',
    borderRadius: '30px',
    fontSize: '1.1em',
    fontWeight: '600',
    cursor: 'pointer',
    letterSpacing: '0.5px',
    transition: 'all 0.3s ease',
    width: '100%',
    marginTop: '30px',
  };

  const modalStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    display: showModal ? 'flex' : 'none', // Flexbox for centering
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    zIndex: '1000',
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '40px',
    borderRadius: '12px',
    width: '400px',
    textAlign: 'center',
  };

  const modalHeadingStyle = {
    fontSize: '2em',
    fontWeight: '700',
    marginBottom: '20px',
  };

  const modalInputStyle = {
    display: 'block',
    padding: '16px 20px',
    width: '100%',
    borderRadius: '12px',
    border: '1px solid #ddd',
    backgroundColor: '#fff',
    fontSize: '1em',
    marginBottom: '15px',
    boxSizing: 'border-box',
  };

  const closeButtonStyle = {
    padding: '10px 20px',
    backgroundColor: '#dc3545',
    color: '#fff',
    border: 'none',
    borderRadius: '30px',
    fontSize: '1em',
    fontWeight: '600',
    cursor: 'pointer',
    marginTop: '10px',
  };

  const handleResetAccount = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSaveReset = () => {
    // Logic for saving/resetting the account
    alert('Account reset successfully!');
    setShowModal(false);
  };

  return (
    <div style={containerStyle}>
      <h2 style={headingStyle}>Account Settings</h2>
      <form>
        <div style={inputContainerStyle}>
          <label style={labelStyle} htmlFor="name">Name</label>
          <input
            id="name"
            type="text"
            style={inputStyle}
            placeholder="Enter your name"
            required
          />
        </div>

        <div style={inputContainerStyle}>
          <label style={labelStyle} htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            style={inputStyle}
            placeholder="Enter your email"
            required
          />
        </div>

        <div style={inputContainerStyle}>
          <label style={labelStyle} htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            style={inputStyle}
            placeholder="Enter your password"
            required
          />
        </div>

        <button
          type="submit"
          style={buttonStyle}
        >
          Save Changes
        </button>
      </form>

      {/* Reset Account Section */}
      <div style={{ marginTop: '40px', textAlign: 'center' }}>
        <button
          onClick={handleResetAccount}
          style={resetButtonStyle}
        >
          Reset Account
        </button>
      </div>

      {/* Modal for Reset Account */}
      {showModal && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <h3 style={modalHeadingStyle}>Reset Account Details</h3>
            <div style={inputContainerStyle}>
              <label style={labelStyle} htmlFor="resetName">New Name</label>
              <input
                id="resetName"
                type="text"
                style={modalInputStyle}
                placeholder="Enter your new name"
                required
              />
            </div>

            <div style={inputContainerStyle}>
              <label style={labelStyle} htmlFor="resetEmail">New Email</label>
              <input
                id="resetEmail"
                type="email"
                style={modalInputStyle}
                placeholder="Enter your new email"
                required
              />
            </div>

            <div style={inputContainerStyle}>
              <label style={labelStyle} htmlFor="resetPassword">New Password</label>
              <input
                id="resetPassword"
                type="password"
                style={modalInputStyle}
                placeholder="Enter your new password"
                required
              />
            </div>

            <button
              type="button"
              onClick={handleSaveReset}
              style={buttonStyle}
            >
              Save Reset
            </button>

            <button
              type="button"
              onClick={handleCloseModal}
              style={closeButtonStyle}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
