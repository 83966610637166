import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Ensure proper imports
import './App.css';
import Home from './components/Home';
import Contact from './components/ContactAs';
import CancellationAndRefundPolicy from './components/CancellationAndRefundPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import Login from './components/Login';
import Faculty from './components/Faculty';
import Event from './components/Event';
import LMSApp from './components/LMS/LMSApp';
import CourseVideos from './components/LMS/CourseVideos';
import Assignment from './components/LMS/Assignment';
import Progress from './components/LMS/Progress';
import Questions from './components/LMS/Questions';

// import Doubt from './components/LMS/Questions';
import Settings from './components/LMS/Settings';
import CourseDetails from './components/LMS/CourseDetails';



function App() {
  return (
    <Router>
      <div className="App">
        

       <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/CancellationAndRefundPolicy" element={<CancellationAndRefundPolicy />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<div>Dashboard Page</div>} />
        <Route path="/courses" element={<div>Courses Page</div>} />
        <Route path="/assignment" element={<Assignment/>} />
        <Route path="/progress" element={<Progress />} />
        <Route path="/questions" element={<Questions/>} />
        <Route path="/settings" element={<Settings />} />
          <Route path="/dasbord" element={<LMSApp />} />
          <Route path="/Faculty" element={<Faculty />} />
          <Route path="/Event" element={<Event />} />
          {/* <Route path="/Settings" element={<Settings />} /> */}
          
          <Route path="/CourseVideos" element={<CourseVideos />} />
          <Route path="/course/:id" element={<CourseDetails />} />



        </Routes>

        
      </div>
    </Router>
  );
}

export default App;